<template>
  <div class="login-bg column items-end full-height relative-position">
    <h4 class="sys-title text-white">{{ sysInfo.sysName || 'Loading...' }}</h4>
    <q-card class="login-card text-white" flat bordered>
      <q-card-section class="relative-position login-header">
        <div class="login-title text-h6 text-center">用户登录</div>
      </q-card-section>
      <q-card-section class="relative-position login-body">
        <q-form class="q-gutter-lg" @submit="login">
          <q-input
            v-model="form.account"
            :rules="rules.account"
            placeholder="请输入用户名/邮箱/手机号"
            maxlength="64"
            dense
            outlined
            hide-bottom-space
          >
            <template v-slot:prepend>
              <q-icon name="account_circle"/>
            </template>
          </q-input>
          <q-input
            v-model="form.password"
            :rules="rules.password"
            placeholder="请输入密码"
            type="password"
            maxlength="64"
            dense
            outlined
            hide-bottom-space
          >
            <template v-slot:prepend>
              <q-icon name="https"/>
            </template>
          </q-input>
          <div>
            <q-btn type="submit" class="full-width" color="primary" unelevated>登录</q-btn>
          </div>
        </q-form>
      </q-card-section>
    </q-card>
    <div class="sys-bottom">
      <div class="q-my-sm">北京农村产权交易所 版权所有 Copyright © 2021 clsc.bjraee.com All Right Reserved</div>
      <div class="row items-center justify-center">
        <a
          class="q-ma-sm"
          href="https://beian.miit.gov.cn"
          target="_blank"
        >
          网站备案： 京ICP备11002127号-1
        </a>
        <a
          class="q-ma-sm row items-center"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502040956"
          target="_blank"
        >
          <img
            style="height:20px;width:20px;"
            src="https://zcgl.bjraee.com/bjraee-ams-manage/cusfiles/2fc1b8d663d77599d43ca67fbf26f527.png" alt=""
          />
          <span>京公网安备 11010502040956号</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, ref } from 'vue'
import { qNotBlank } from '../utils/validate-utils'

export default {
  name: 'Login',
  setup () {
    const { proxy } = getCurrentInstance()
    const sysInfo = ref({})
    const form = ref({
      account: null,
      password: null
    })
    const rules = ref({
      account: [qNotBlank],
      password: [qNotBlank]
    })

    async function loadSysInfo () {
      sysInfo.value = await proxy.$server.post('/sysConfig/sysInfo')
      if (sysInfo.value.sysName) {
        document.title = sysInfo.value.sysName
      }
    }

    async function login () {
      const param = form.value
      const result = await proxy.$server.post('/login', param)
      sessionStorage.setItem('__CONTRACT_MS_SESSION_TOKEN__', result.token)
      const back = proxy.$route.query.back
      if (back) {
        location.replace(back)
      } else {
        proxy.$router.replace('/')
      }
    }

    loadSysInfo()

    return {
      sysInfo,
      form,
      rules,
      login
    }
  }
}
</script>

<style lang="scss" scoped>
.sys-title {
  position: absolute;
  top: 5vh;
  left: 5vh;
  margin: 0;
}

.sys-bottom {
  position: absolute;
  left: 0;
  bottom: 20px;
  width: 100%;
  color: #fff;
  text-align: center;

  a {
    color: #fff;
    text-decoration: none;
  }
}

.login-bg {
  background: url('../assets/login-bg.jpg') no-repeat #122a60;
  background-size: cover;
}

.login-card {
  width: 90vw;
  max-width: 320px;
  margin-right: 5vw;
  margin-top: 25vh;
  background: rgba(#fff, 0.1);
  border: 2px solid #122a60;

  .login-title {
    letter-spacing: 3px;
  }

  ::v-deep .material-icons {
    color: #fff;
  }

  ::v-deep .q-field--outlined .q-field__control:before {
    border: 1px solid #0385B6;
  }

  ::v-deep .q-checkbox__bg {
    border: 1px solid #0385B6;
  }

  ::v-deep .q-field__native {
    color: #fff;
  }

  ::v-deep .q-field__native::placeholder {
    color: #fff;
    opacity: 1;
  }

  .login-header {
    &:before, &:after {
      content: "";
      display: block;
      position: absolute;
      pointer-events: none;
      border: 4px solid #00b8ff;
      width: 45px;
      height: 45px;
      top: -4px;
      border-bottom: 0;
    }

    &:before {
      left: -4px;
      border-right: 0;
    }

    &:after {
      right: -4px;
      border-left: 0;
    }
  }

  .login-body {
    &:before, &:after {
      content: "";
      display: block;
      position: absolute;
      pointer-events: none;
      border: 4px solid #00b8ff;
      width: 45px;
      height: 45px;
      bottom: -4px;
      border-top: 0;
    }

    &:before {
      left: -4px;
      border-right: 0;
    }

    &:after {
      right: -4px;
      border-left: 0;
    }
  }

}
</style>
